<template>
  <div>
    <div class="title-tabs">Genaral Information</div>
    <div class="bg-white px-3 py-3">
      <b-row>
        <b-col cols="12">
          <InputText
            textFloat="Name"
            placeholder="Name"
            type="text"
            name="name"
            isRequired
            v-model="form.name"
            :v="v.name"
            :isValidate="v.name.$error"
          />
        </b-col>
        <!-- {{ form.image_url }} -->
        <b-col md="6">
          <UploadFile
            textFloat="Icon"
            placeholder="Please Choose File"
            format="video"
            :fileName="form.icon"
            v-model="form.icon"
            name="thumbnail"
            text="*Please upload only .png, .jpg image with ratio 1:1 less than 10 MB"
            isRequired
            accept="image/jpeg, image/png"
            v-on:onFileChange="(val) => onImageChange(val, 'icon', 1)"
            :disabled="isDisable"
            :v="v.icon"
            :isValidate="v.icon.$error"
          />
          <!-- :v="$v.form.icon" -->
          <PreviewBox
            v-on:delete="deleteImage('icon')"
            :disabled="isDisable"
            :showPreviewType="0"
            :showPreview="form.icon"
            :isLoadingImage="isLoadingImage['icon']"
            :ratioType="1"
          />
        </b-col>

        <b-col md="6">
          <UploadFile
            textFloat="Banner"
            placeholder="Please Choose File"
            format="video"
            :fileName="form.banner"
            v-model="form.banner"
            name="thumbnail"
            text="*Please upload only .png, .jpg image with ratio 16:9 less than 10 MB"
            isRequired
            accept="image/jpeg, image/png"
            v-on:onFileChange="(val) => onImageChange(val, 'banner', 4)"
            :disabled="isDisable"
            :v="v.banner"
            :isValidate="v.banner.$error"
          />
          <!-- :v="$v.form.banner" -->
          <PreviewBox
            v-on:delete="deleteImage('banner')"
            :disabled="isDisable"
            :showPreviewType="0"
            :showPreview="form.banner"
            :isLoadingImage="isLoadingImage['banner']"
            :ratioType="16"
          />
        </b-col>

        <b-col cols="12" xl="6" class="mt-2">
          <b-form-group label="Valid Date" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              id="valid-radio-1"
              v-model="form.valid_type"
              :options="validSettingOptions"
              :aria-describedby="ariaDescribedby"
              value-field="value"
              text-field="text"
              name="valid-radio"
              :disabled="userRedeem"
            ></b-form-radio-group>
          </b-form-group>
          <div v-if="form.valid_type == 1">
            <InputText
              textFloat=""
              v-model="form.invalid_after_redeem"
              placeholder="0"
              type="number"
              :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 5);'"
              class="mb-0"
              :v="v.invalid_after_redeem"
              :disabled="userRedeem"
              :isValidate="v.invalid_after_redeem.$error"
            />
          </div>
          <b-row v-if="form.valid_type == 2">
            <b-col xs sm="12" md lg xl="6">
              <InputDatePickerFilter
                textFloat="Valid From"
                name="valid-form"
                ref="valid-form"
                format="dd/MM/yyyy (HH:mm)"
                :value="form.valid_from"
                v-model="form.valid_from"
                type="datetime"
                :v="v.valid_from"
                :isRequired="true"
                :maxDatetime="form.valid_to"
                :isValidate="v.valid_from.$error"
                :disabled="userRedeem"
              />
            </b-col>
            <b-col xs sm="12" md lg xl="6" class="mt-3 mt-md-0">
              <InputDatePickerFilter
                textFloat="Valid To"
                name="valid-to"
                ref="valid-to"
                format="dd/MM/yyyy (HH:mm)"
                :value="form.valid_to"
                v-model="form.valid_to"
                type="datetime"
                :v="v.valid_to"
                :minDatetime="form.valid_from"
                :isRequired="true"
                :isValidate="v.valid_to.$error"
                :disabled="userRedeem"
              />

              <!-- -->
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" xl="6" class="mt-2">
          <b-form-group label="Customer Segment" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              id="customer-segment"
              v-model="form.mission_customer_segment_id"
              :options="[
                { text: 'All', value: 3, disabled: userRedeem },
                {
                  text: 'Personalize',
                  value: 1,
                  disabled: userRedeem,
                },
                {
                  text: 'Segment',
                  value: 2,
                  disabled: userRedeem || missionType == 1,
                },
              ]"
              :aria-describedby="ariaDescribedby"
              name="customer-segment-options"
              @change="clearValue"
            ></b-form-radio-group>
          </b-form-group>
          <InputSelect
            v-if="form.mission_customer_segment_id == 2"
            name="segment"
            isRequired
            customClass="mb-0"
            v-model="form.customer_segment_value"
            v-bind:options="segmentList"
            valueField="id"
            textField="name"
            :v="v.customer_segment_value"
            :isValidate="v.customer_segment_value.$error"
          >
            <template v-slot:option-first>
              <b-form-select-option value="" disabled>
                -- Select Segment --
              </b-form-select-option>
            </template>
          </InputSelect>
        </b-col>
        <b-col cols="12" xl="6" class="mt-2">
          <!-- , -->
          <!-- { text: 'Point', value: 3 }, -->
          <b-form-group label="Goal" v-slot="{ ariaDescribedby }">
            <b-form-radio-group
              id="mission_redeem_type"
              v-model="form.mission_redeem_type"
              :options="[
                { text: 'Spending (Baht)', value: 1 },
                { text: 'Frequency', value: 2 },
              ]"
              :aria-describedby="ariaDescribedby"
              :disabled="userRedeem"
              name="mission_redeem_type-options"
            ></b-form-radio-group>
          </b-form-group>

          <InputText
            v-if="
              form.mission_redeem_type == 1 || form.mission_redeem_type == 3
            "
            textFloat=""
            v-model="form.mission_redeem_goal"
            placeholder="0"
            type="number"
            :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 7);'"
            class="mb-0"
            :v="v.mission_redeem_goal"
            :disabled="userRedeem"
            :isValidate="v.mission_redeem_goal.$error"
          />

          <InputSelect
            v-if="form.mission_redeem_type == 2"
            name="mission_redeem_condition"
            isRequired
            customClass="mb-0"
            v-model="form.mission_redeem_condition"
            v-bind:options="[
              { id: 1, name: 'Quantity' },
              { id: 2, name: 'Spending' },
            ]"
            :disabled="userRedeem"
            valueField="id"
            textField="name"
          >
            <template v-slot:option-first>
              <b-form-select-option value="0" disabled>
                -- Select Type --
              </b-form-select-option>
            </template>
          </InputSelect>
        </b-col>
        <b-col cols="12" class="mt-2">
          <div>
            <b-row>
              <b-col sm="12">
                <label class="ft-16 main-label">Products</label>
                <b-form-group class="d-flex mt-2">
                  <b-form-radio-group
                    v-model="isAllProduct"
                    name="product-radios"
                    class="text-black"
                    :options="isAllProductOption"
                    @change="handleProduct"
                  ></b-form-radio-group>
                </b-form-group>
              </b-col>
              <b-col sm="12" v-if="isAllProduct == 0">
                <div class="content-between">
                  <div>
                    <b-button variant="select-branch" @click="openProductModal">
                      Select Product<font-awesome-icon
                        icon="chevron-right"
                        class="ml-2"
                      />
                    </b-button>
                    <span class="ml-2"
                      >(Selected Product
                      {{
                        form.select_product
                          ? form.select_product.length
                          : 0 | numeral("0,0")
                      }}
                      Item)
                    </span>
                  </div>
                </div>

                <div class="mt-3">
                  <b-table
                    responsive
                    striped
                    hover
                    :fields="fieldsProduct"
                    :items="form.select_product_detail"
                    :busy.sync="isBusy"
                    :perPage="filter.take"
                    :currentPage="filter.page"
                    @context-changed="tableChange"
                    show-empty
                    empty-text="No matching records found"
                  >
                    <template v-slot:cell(imageUrl)="data">
                      <div>
                        <div
                          v-if="data.item.imageUrl"
                          class="box-image"
                          v-bind:style="{
                            'background-image':
                              'url(' + data.item.imageUrl + ')',
                          }"
                        ></div>

                        <div
                          v-else-if="data.item.image_url"
                          class="box-image"
                          v-bind:style="{
                            'background-image':
                              'url(' + data.item.image_url + ')',
                          }"
                        ></div>
                        <div v-else>-</div>
                      </div>
                    </template>
                    <template v-slot:cell(image_url)="data">
                      <div>
                        <div
                          v-if="data.item.image_url"
                          class="box-image"
                          v-bind:style="{
                            'background-image':
                              'url(' + data.item.image_url + ')',
                          }"
                        ></div>
                        <div v-else>-</div>
                      </div>
                    </template>
                    <template v-slot:cell(name)="data">
                      <div class="text-left">
                        <div v-if="data.item.name">
                          <p class="m-0">
                            {{ data.item.name }}
                          </p>
                          <p class="m-0 text-gray">
                            {{ data.item.barcode }}
                          </p>
                        </div>
                        <p v-else class="m-0">-</p>
                      </div>
                    </template>
                    <template v-slot:cell(price)="data">
                      {{ data.item.price | numeral("0,0.00") }}
                    </template>
                    <template v-slot:cell(delete)="data">
                      <span class="pointer" @click="deleteProduct(data.item)"
                        ><b-icon
                          icon="trash-fill"
                          class="text-bg-primary"
                        ></b-icon
                      ></span>
                    </template>
                    <template v-slot:table-busy>
                      <div class="text-center text-black my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong class="ml-2">Loading...</strong>
                      </div>
                    </template>
                  </b-table>
                </div>

                <Pagination
                  @handleChangeTake="handleChangeTake"
                  :pageOptions="pageOptions"
                  :filter="filter"
                  :rows="rows"
                  @pagination="pagination"
                />

                <hr />
              </b-col>
            </b-row>
            <!-- Select Product -->

            <!-- Branch -->
            <div>
              <label class="main-label"
                >Participating Branches
                <span class="text-danger">*</span>
              </label>

              <div v-if="branchList && branchList.length > 0">
                <ModalSelectBranch
                  :list="branchList"
                  @deleteBranch="(val) => (deleteBranchArry = val)"
                  @selectBranch="selectBranch"
                  text="Select Branch"
                  :id="id"
                  :BranchSelectList="form.select_branch || []"
                  :valid="chkValid"
                />
                <div v-if="v.select_branch.$error" class="text-error">
                  Please select value.
                </div>
              </div>
            </div>
          </div>
        </b-col>
        <b-col cols="12" class="mt-3">
          <TextEditorsTiny
            textFloat="Detail"
            placeholder="Detail"
            :value="form.description"
            @onDataChange="(val) => (form.description = val)"
            isRequired
            v-model="form.description"
            :v="v.description"
            :isValidate="v.description.$error"
          />
        </b-col>
      </b-row>
      <ProductModal v-on:submitProduct="submitProduct" ref="ProductListModal" />
      <div class="mt-3">
        <b-form-checkbox
          switch
          v-model="form.status"
          class="radio-status"
          size="lg"
          :value="1"
          :unchecked-value="0"
        >
          <span class="ml-2 main-label">{{
            form.status == 1 ? "Active" : "Inactive"
          }}</span>
        </b-form-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import PreviewBox from "@/components/PreviewBox.vue";

import ModalSelectProduct from "@/components/coupon/detail/ModalSelectProduct";
import SelectProductList from "@/components/coupon/detail/SelectProductList";
import ModalSelectBranch from "@/components/ModalSelectBranch";
import ProductModal from "./ProductModal.vue";
export default {
  name: "missionInfo",
  components: {
    PreviewBox,
    ModalSelectProduct,
    SelectProductList,
    ModalSelectBranch,
    ProductModal,
  },
  props: {
    form: {
      required: true,
    },
    v: {
      required: true,
    },
    userRedeem: {
      required: false,
    },
    missionType: {
      required: false,
    },
  },
  data() {
    return {
      imagePath: "",
      isLoadingImage: { banner: false, icon: false },
      validSettingOptions: [
        { value: 1, text: "After Redeemed" },
        { value: 2, text: "Specific Date" },
      ],
      branchList: [],
      productList: [],
      rows: 0,
      filter: { page: 1, take: 5 },
      chkProduct: 0,
      id: this.$route.params.id,
      errorMessageBranch: "",
      isDisable: false,
      allUnSelected: false,
      chkValid: 0,
      countIdUpdate: [],
      isAllProductOption: [
        { text: "All Products", value: 1 },
        { text: "Selected Item", value: 0 },
      ],
      isAllProduct: 1,
      fieldsProduct: [
        {
          key: "imageUrl",
          label: "Image",
        },
        {
          key: "name",
          label: "Product Name (SKU)",
          class: "text-left",
        },
        {
          key: "price",
          label: "Price",
        },
        {
          key: "delete",
          label: "",
        },
      ],
      productSelect: [],
      originalProductSelect: [],

      showingTo: 10,
      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      isBusy: false,
      segmentList: [],
    };
  },
  created() {
    this.getBranchList();
    this.getSegmentList();
    if (this.form.select_product_detail) {
      this.isAllProduct = 0;
      this.rows = this.form.select_product_detail.length;
    }
  },
  methods: {
    deleteImage(key) {
      this.form[key] = "";
    },
    onImageChange(val, key, ratio) {
      this.isLoadingImage[key] = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(val);
      reader.onload = async () => {
        let img = new Image();
        img.onload = async () => {
          // if (mode == "icon") {
          if (val.size > 103384000)
            return this.$swal("This file is too large", {
              icon: "info",
            });
          if (ratio == 1) {
            let message = "Please upload image with ratio 1:1 !";
            var width = parseInt(img.width);
            var height = parseInt(img.height);
            if (parseInt(width) !== parseInt(height)) {
              this.$swal(message, {
                icon: "info",
              });
              this.ratioMatch = false;
              this.isLoadingImage[key] = false;
              this.isDisable = false;
              return this.deleteImage(key);
            }
          } else {
            var width = parseInt(img.width) / 16;
            var height = parseInt(img.height) / 9;

            if (parseInt(width) !== parseInt(height)) {
              this.$swal("Please upload image with ratio 16:9 !", {
                icon: "info",
              });
              this.ratioMatch = false;
              this.isLoadingImage[key] = false;
              this.isDisable = false;
              return this.deleteImage(key);
            }
          }
          // }

          this.ratioMatch = true;
          this.setUpdataImage(reader.result, key);
        };
        img.src = reader.result;
      };
    },
    async setUpdataImage(base64, key) {
      if (this.ratioMatch === true) {
        var url = await this.saveImagetoDb(base64, false);

        this.isLoadingImage[key] = false;
        this.isDisable = false;

        this.form[key] = url;
        // this.form.banner.is_video = 0;
      }
    },
    saveImagetoDb: async function (img, isvideo) {
      var form = {
        Base64String: img,
        is_video: isvideo,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      } else {
        alert("Unsuccess!");
      }
    },
    async getBranchList() {
      let id = 0;

      await this.$store.dispatch("getBranch", id);
      if (!this.$store.state.coupon.modalAlertLogin) {
        this.branchList = [...this.$store.state.coupon.branchList.detail];
        if (this.id != "0") {
          for (let i in this.branchList) {
            for (let j in this.branchList[i].branch) {
              if (
                this.form.select_branch.includes(
                  this.branchList[i].branch[j].id
                )
              ) {
                this.branchList[i].branch[j].is_check = true;
              }
            }
          }
        }
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    },
    selectBranch(val) {
      this.form.select_branch = val;
    },
    clearValue() {},
    submitProduct(select, list) {
      this.form.select_product = select;
      this.form.select_product_detail = list;
      this.rows = select.length;

      this.filter.page = 1;
    },
    async openProductModal() {
      await this.$refs.ProductListModal.show(
        this.form.select_product,
        this.form.select_product_detail
      );
    },

    tableChange(e, a) {
      this.isBusy = true;
      setTimeout(() => {
        this.isBusy = false;
      }, 500);
    },
    async getSegmentList() {
      const result = await this.axios("/customer/SegmentFilter");

      if (result.data.result == 1) {
        this.segmentList = result.data.detail;
      }
    },
    deleteProduct(data) {
      this.form.select_product.splice(
        this.form.select_product.findIndex((el) => el == data.id),
        1
      );
      this.form.select_product_detail.splice(
        this.form.select_product_detail.findIndex((el) => el.id == data.id),
        1
      );
    },
    handleChangeTake() {},
    pagination() {},
    handleProduct(val) {
      if (val == 0 && !this.form.select_product) this.form.select_product = [];
      else if (val == 1) this.form.select_product = null;
    },
  },
};
</script>

<style></style>
